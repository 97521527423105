<template>
	<div :class="!pageName ? 'container' : ''">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			showOrder
			:activeTab="activeTab"
			custom
		/>
		<el-dialog width="75vw" center :visible.sync="createVisible">
			<div style="min-height: 300px">
				<createPlan :defaultData="defaultData" v-if="createVisible" @cancel="handleCancel" :pageName="currentPageName" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
/* 评估中心 */
import baseTable from '@/views/components/components-base-table';
import { downloadByCreateA } from '@/utils/util';
import moment from 'moment';
import createPlan from './createPlan';

export default {
	name: 'jobApprovalQuery',
	components: {
		baseTable,
		createPlan
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		currentPageName() {
			return this.pageName || this.$route.name;
		},
		applyTypeList() {
			return this.$store.state.approval.applyTypeList;
		},
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		otherBtns() {
			return [
				this.permissionControlBtns(this.currentPageName, 'Add') && {
					icon: 'el-icon-plus',
					label: '创建计划',
					click: () => {
						this.defaultData = { type: 'add' };
						this.createVisible = true;
					}
				},
				this.permissionControlBtns(this.currentPageName, 'Export') && {
					icon: 'el-icon-download',
					label: '导出',
					click: () => {
						const params = { ...this.queryData };

						this.$delete(params, 'pageNo');
						this.$delete(params, 'pageSize');
						downloadByCreateA(`/v1/internal/talent/exportPlanDetail?params=${encodeURIComponent(JSON.stringify(params))}`);
					}
				}
			].filter((v) => v);
		},
		// 超级管理员
		isSuperAdmin() {
			return ['1'].includes(this.userInfo.userType);
		},
		// 管理员（项目经理）
		isAdmin() {
			return ['2'].includes(this.userInfo.userType);
		}
	},
	data() {
		return {
			createVisible: false,
			defaultData: {},
			tableCols: [
				{
					label: '计划名称',
					key: 'planName'
				},
				{
					label: '项目经理',
					key: 'projectManagerName'
				},
				{
					label: '公司名称',
					key: 'company'
				},
				{
					label: '评估类型',
					key: 'evaluationTypeName'
				},
				{
					label: '开始时间',
					key: 'startTime',
					formatter: (val) => moment(val).format('YYYY-MM-DD')
				},
				{
					label: '结束时间',
					key: 'endTime',
					formatter: (val) => moment(val).format('YYYY-MM-DD')
				},
				{
					label: '评估说明',
					key: 'remark'
				},
				{
					label: '状态',
					key: 'status',
					width: 120,
					formatter: (v) => this.statusOpts.find((it) => it.value === v)?.label
				},
				{
					label: '操作',
					key: 'cz',
					width: '120px',
					fixed: 'right',
					render: (row) => {
						// 可以编辑
						// const editable = (['0', '1'].includes(row.status) && this.isAdmin) || this.isSuperAdmin;
						// 可以删除
						// const canDelete = this.isSuperAdmin;
						// 可以取消
						// const canCancle = (['0', '1'].includes(row.status) && this.isAdmin) || (['4'].includes(row.status) && this.isSuperAdmin);
						const editable = true;
						const canDelete = true;
						const canCancle = true;

						return (
							<div style="height:22px;display: inline-flex;align-items: center">
								<el-popover placement="left" width="96" trigger="hover">
									<el-button type="text" slot="reference" onClick={(e) => e.stopPropagation()}>
										<img src={require('../../../assets/button/操作图标@3x.png')} width="16px" />
									</el-button>
									<ul class="drop-menu">
										{editable && this.permissionControlBtns(this.currentPageName, 'Edit') && (
											<li class="menu-item" onClick={() => this.editPlan(row)}>
												编辑计划
											</li>
										)}
										{canCancle && this.permissionControlBtns(this.currentPageName, 'Cancel') && (
											<li class="menu-item" onClick={() => this.cancelPlan(row)}>
												取消计划
											</li>
										)}
										{canDelete && this.permissionControlBtns(this.currentPageName, 'Delete') && (
											<li class="menu-item" onClick={() => this.delPlan(row)}>
												删除计划
											</li>
										)}
									</ul>
								</el-popover>
							</div>
						);
					}
				}
			],
			queryItem: [
				{ label: '时间范围', key: 'date', type: 'daterange', span: 6 },
				{ label: '计划名称', key: 'planName', type: 'input', span: 6 },
				{
					label: '状态',
					key: 'status',
					type: 'select',
					options: () => this.statusOpts.map(({ label, value }) => ({ dictId: value, dictName: label })),
					span: 6
				}
			],

			formModel: {},
			statusOpts: [
				{ label: '待开始', value: '0' },
				{ label: '进行中', value: '1' },
				{ label: '已完成', value: '2' },
				{ label: '已取消', value: '3' },
				{ label: '已截止', value: '4' }
			],
			queryData: {}
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			if (params.request.date?.length) {
				params.request.startTime = params.request.date[0];
				params.request.endTime = params.request.date[1];
				this.$delete(params.request, 'date');
			}
			let pageType = '';

			switch (this.currentPageName) {
				case 'EvaluationPlan':
					pageType = '1';
					break;
				case 'EmployeeEvaluationPlan':
					pageType = '2';
					break;

				default:
					break;
			}
			params.request.pageType = pageType;
			this.queryData = params.request;
			callback(this.$axios.post('/v1/internal/talent/getPlanList', params));
		},
		returnStatusColor(status) {
			// eslint-disable-next-line default-case
			switch (status) {
				case 1:
					return '#64C940';
				case 2:
					return '#FF604D';
				case 0:
					return '#409EFF';
			}
		},
		// 点击table行
		rowClick(row) {
			// this.$router.push({
			// 	name: 'CreateAssessPlan',
			// 	query: { params: setUrlParams({ type: 'view', id: row.id }) }
			// });
			this.defaultData = { type: 'view', id: row.id };
			this.createVisible = true;
		},
		editPlan(row) {
			// this.$router.push({
			// 	name: 'CreateAssessPlan',
			// 	query: { params: setUrlParams({ type: 'edit', id: row.id }) }
			// });
			this.defaultData = { type: 'edit', id: row.id };
			this.createVisible = true;
		},
		// 取消计划
		cancelPlan({ id }) {
			this.$confirm('确认取消计划吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios.post('/v1/internal/talent/cancelPlan', { request: { id } }).then((res) => {
						if (res.code) {
							return;
						}
						this.$message.success('取消成功');
						this.$refs.baseTable?.handleSearch();
					});
				})
				.catch(() => {});
		},
		// 删除计划
		delPlan({ id }) {
			this.$confirm('确认删除计划吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios.post('/v1/internal/talent/deletePlan', { request: { id } }).then((res) => {
						if (res.code) {
							return;
						}
						this.$message.success('删除成功');
						this.$refs.baseTable?.handleSearch();
					});
				})
				.catch(() => {});
		},
		handleCancel() {
			this.createVisible = false;
			this.$refs.baseTable?.handleSearch();
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 30px;
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>

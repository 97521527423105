<template>
	<div>
		<el-tabs v-model="activeName">
			<el-tab-pane :lazy="true" label="待处理" name="1" v-if="permissionControl(pageNameByType('1'))">
				<PendingAssessment ref="pane1" :pageName="pageNameByType('1')"></PendingAssessment>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="已处理" name="2" v-if="permissionControl(pageNameByType('2'))">
				<PendingAssessment ref="pane2" :pageName="pageNameByType('2')"></PendingAssessment>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
/* 我测评的 */
import PendingAssessment from './pendingAssessment'; // 待处理

// import * as api from "@/api/workbench";
export default {
	name: 'iEvaluated',
	components: {
		PendingAssessment
	},
	props: {
		pageName: {}
	},
	data() {
		return {
			activeName: '1',
			listQuery: {}
		};
	},

	computed: {
		pageNameByType() {
			return (type) => {
				let name = '';

				switch (this.pageName) {
					case 'IEvaluated':
						name = type === '1' ? 'PendingAssessment' : 'ProcessedAssessments';
						break;
					case 'IReviewed':
						name = type === '1' ? 'PendingReviewed' : 'ProcessedReviewed';
						break;

					default:
						break;
				}
				return name;
			};
		}
	},
	created() {},
	methods: {},
	mounted() {
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: this.pageNameByType('1') },
			{ name: '2', key: this.pageNameByType('2') }
		]);
	}
};
</script>
<style scoped>
.staffWrapBox {
	background: #fff;
	/* margin: 0 20px; */
	padding: 10px 30px;
}
</style>

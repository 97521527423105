<template>
	<div class="assessBoxCreate">
		<div class="app-container">
			<div class="titlebox">
				<!-- <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button> -->
				<div class="title">{{ title }}</div>
			</div>
			<div class="formBox">
				<formItem
					class="queryForm"
					ref="formItem"
					:formItems="formItem"
					@cancel="$emit('cancel')"
					:defaultData="formModel"
					:showFooter="!this.isView"
					:type="this.isView ? 'view' : ''"
					@submit="submit"
				>
					<div style="font-size: 14px; color: #409eff; margin-left: 30px; margin-bottom: 10px" v-if="uploadData.name">
						{{ uploadData.name
						}}<i class="el-icon-delete" style="color: red; cursor: pointer; margin-left: 30px" @click="uploadData = {}"></i>
					</div>
					<baseTable
						ref="baseTable"
						:columns="dialogDutyItems(0)"
						showOrder
						:queryItem="queryItems"
						:dataList="userList"
						style="margin-bottom: 30px"
						v-show="userList.length"
					/>
				</formItem>
			</div>
		</div>
		<!-- 导入按钮的弹窗 -->
		<Upload :visible.sync="dialogImportVisible" :config="uploadConfig" />

		<el-dialog width="900px" center title="添加评估对象" :visible.sync="addVisible" append-to-body>
			<div>
				<div v-for="(item, inx) in dialogList" :key="inx" style="padding: 20px; border: 1px dashed #bfbfbf; margin-bottom: 10px">
					<formItem
						:ref="`formItemList`"
						:formItems="dialogDutyItems(inx).filter(({ key }) => key !== 'cz')"
						:defaultData="item"
						:showFooter="false"
					/>
					<div style="text-align: center; cursor: pointer" v-if="dialogList.length > 1">
						<i class="el-icon-delete" style="color: red" @click="dialogList.splice(inx, 1)"></i>
					</div>
				</div>
				<div class="addFunctionsModal" @click="dialogList.push({})"><i class="el-icon-plus" style="margin-right: 4px"></i>添加更多</div>
			</div>
			<div style="text-align: center">
				<el-button @click="addVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 新增编辑单个被评估人 -->
		<el-dialog width="900px" center :title="rtitle" :visible.sync="editVisible" append-to-body>
			<formItem
				ref="formItemOne"
				:formItems="dialogDutyItems(0).filter(({ key }) => key !== 'cz')"
				:defaultData="editData"
				@cancel="editVisible = false"
				@submit="handleEditSubmit"
			/>
		</el-dialog>
		<!-- 上传 -->
		<!-- <el-dialog width="900px" center :title="rtitle" :visible.sync="uploadVisible">
			<div style="text-align: center; margin: 20px 0">
				<el-upload
					class="upload-demo"
					action="#"
					:auto-upload="false"
					drag
					:file-list="fileList"
					:on-change="handleFileChange"
					:limit="1"
					:on-remove="handleRemove"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传xlsx文件</div>
				</el-upload>
			</div>
			<div class="import_dialog_desc">
				<span class="import_dialog_desc_download_text" @click="downloadExcel">Excel模板下载</span>
				<span>（注：导入操作请务必以下载的模板为准！）</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="uploadVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleUpload">确 定</el-button>
			</span>
		</el-dialog> -->
	</div>
</template>
<script>
// import Departments from '@/components/departs';
import { checkFormList } from '@/utils/util';
import formItem from '@/views/components/components-form-item/index.vue';
import baseTable from '@/views/components/components-base-table';
import moment from 'moment';
import { Loading } from 'element-ui';
import Upload from '@/components/Upload/index.vue';
// import { json2excel } from '../../../utils/util';
export default {
	props: { defaultData: {}, pageName: {} },
	components: { formItem, Upload, baseTable },
	computed: {
		// defaultData() {
		// 	return JSON.parse(getdefaultData(this.$route.query.params));
		// },
		dialogDutyItems() {
			return (inx) => {
				const evaluateUser = {
					label: '评估人',
					key: 'evaluateUserId',
					showKey: 'evaluateUserName',
					type: 'selectSearch',
					span: 8,
					firstRequest: true, // 开始直接请求一次
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '3' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name }))),
					rules: () => this.rules.select,
					formatter: (e, { evaluateUserName }) => evaluateUserName
				};
				const reviewUser = {
					label: '复核人',
					key: 'reviewUserId',
					showKey: 'reviewUserName',
					type: 'selectSearch',
					span: 8,
					firstRequest: true,
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '2' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name }))),
					rules: () => this.rules.select,
					formatter: (e, { reviewUserName }) => reviewUserName
				};

				// 第一个 才进行请求， 其余复用
				if (inx > 0) {
					evaluateUser.firstRequest = false;
					reviewUser.firstRequest = false;
				}
				return [
					{ label: '姓名', key: 'userName', type: 'input', span: 8, rules: () => this.rules.input },
					{ label: '职位', key: 'officeName', type: 'input', span: 8, rules: () => this.rules.input },
					{ label: '直接上级', key: 'directSupervisor', type: 'input', span: 8 },
					{ label: '下属人数', key: 'subordinateNum', type: 'inputNumber', span: 8, precision: 0, min: 0 },
					{ label: '一级组织', key: 'firstOrg', type: 'input', span: 8, rules: () => this.rules.input },
					{ label: '二级组织', key: 'secondOrg', type: 'input', span: 8 },
					{ label: '三级组织', key: 'thirdOrg', type: 'input', span: 8 },
					{ label: '四级组织', key: 'fourthOrg', type: 'input', span: 8 },
					evaluateUser,
					reviewUser,
					!this.isView && {
						label: '操作',
						key: 'cz',
						render: (row) => (
							<div>
								{
									<el-button type="text" onclick={() => this.handleEdit(row)}>
										编辑
									</el-button>
								}
								{
									<el-button type="text" style={'color:rgb(245, 108, 108); '} onclick={() => this.handleDel(row)}>
										删除
									</el-button>
								}
							</div>
						)
					}
				].filter((v) => v);
			};
		},
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		}
	},
	watch: {
		userList(val) {
			if (val?.length > 20) {
				this.queryItems = this.queryItem;
			} else {
				this.queryItems = null;
			}
		}
	},
	data() {
		const validateStaff = ({ message }, value, callback) => {
			if (this.userList.length || this.uploadData.name) {
				callback();
			} else {
				callback(new Error(message || 'error'));
			}
		};

		return {
			isEdit: false,
			isView: false,
			title: '',
			rtitle: '',
			type: '',
			formItem: [
				{ label: '计划名称', key: 'planName', type: 'input', span: 8, rules: () => this.rules.input },
				{ label: '公司名称', key: 'company', type: 'input', span: 8, rules: () => this.rules.input },
				{
					label: '所属行业',
					key: 'industry',
					type: 'cascader',
					async: () => this.$axios.get('/v1/common/getIndustryList'),
					props: {
						// checkStrictly: true,
						// expandTrigger: 'hover',
						label: 'name',
						value: 'id',
						children: 'child',
						emitPath: false
					},
					showAllLevels: false,
					// options: window.$industryList,
					span: 8,
					rules: () => this.rules.input
				},
				{
					label: '属于行业标杆',
					key: 'isIndustryBenchmark',
					type: 'select',
					options: [
						{ dictId: '1', dictName: '是' },
						{ dictId: '0', dictName: '否' }
					],
					span: 8,
					rules: () => this.rules.select
				},
				{
					label: '所属省份城市',
					key: 'city', // provinceCode cityCode
					type: 'cascader',
					options: window.$cityList,
					props: {
						label: 'label',
						value: 'value',
						children: 'children'
					},
					// showAllLevels: false,
					span: 8,
					rules: () => this.rules.select
				},
				{ label: '评估类型', key: 'evaluationType', type: 'select', span: 8, groupId: 'evaluationType', rules: () => this.rules.select },
				{
					label: '起止时间', // startTime endTime
					key: 'date',
					type: 'daterange',
					span: 8,
					rules: () => this.rules.select
				},
				{
					label: '项目经理',
					key: 'projectManager',
					showKey: 'projectManagerName',
					type: 'selectSearch',
					span: 8,
					firstRequest: true, // 开始直接请求一次
					async: (name) =>
						this.$axios
							.post('/v1/internal/talent/getAppraiserList', {
								request: { name, pageNo: 1, pageSize: 40, status: '1', projectRole: '1' }
							})
							.then((res) => res?.list?.map(({ staffId, name }) => ({ dictId: staffId, dictName: name }))),
					rules: () => this.rules.select,
					disabled: () => ['EmployeeEvaluationPlan'].includes(this.pageName),
					formatter: (e, { projectManagerName }) => projectManagerName
				},
				{ label: '评估说明', key: 'remark', type: 'textarea', span: 8 },
				{
					label: '被评估人',
					key: 'userName',
					span: 24,
					render: () => (
						<div>
							{!this.isView && (
								<el-button
									icon="el-icon-plus"
									type="text"
									onClick={() => {
										this.addVisible = true;
										this.dialogList = [{}];
									}}
								>
									添加
								</el-button>
							)}
							{!this.isView && !this.isEdit && (
								<el-button
									icon="el-icon-upload"
									type="text"
									onClick={() => {
										this.rtitle = '请选择上传文件';
										this.dialogImportVisible = true;
									}}
								>
									导入
								</el-button>
							)}
						</div>
					),
					rules: () => this.rules.selectedStaffs
				}
			],
			rules: {
				input: [{ required: true, message: '请输入', trigger: 'change' }],
				select: [{ trigger: 'change', required: true, message: '请选择' }],
				selectedStaffs: [{ trigger: 'change', required: true, message: '请添加或导入被评估人', validator: validateStaff }]
			},
			selectedStaffs: [],
			subLoading: false,
			delList: [],
			formModel: {},
			// 导入配置
			uploadConfig: {
				dictId: 'importInternalTalentEvaluation',
				groupId: 'importTemplate',
				importUrl: '/v1/common/upload/tempFile',
				successMsg: '导入成功',
				callBack: (data) => {
					this.uploadData = data;
					this.dialogImportVisible = false;
				}
			},
			dialogImportVisible: false,
			addVisible: false,
			dialogList: [{}],
			userList: [],
			uploadData: {},
			editVisible: false,
			editData: {},
			deleteUserIds: [],
			fileList: [],
			uploadVisible: false,
			updateUserIds: [],
			queryItem: [
				{ label: '姓名', key: 'userName', type: 'input', span: 6 },
				{ label: '评估人', key: 'evaluateUserName', type: 'input', span: 6 },
				{ label: '复核人', key: 'reviewUserName', type: 'input', span: 6 }
			],
			queryItems: null
			// halfCheckedKeys: []
		};
	},
	mounted() {
		const { defaultData } = this;

		this.type = defaultData.type;
		if (defaultData.type === 'add') {
			this.title = '创建评估计划';
			// 员工端创建评估 经理人默认登录人
			if (['EmployeeEvaluationPlan'].includes(this.pageName)) {
				const { staffId, nickName } = this.userInfo;

				this.formModel = { projectManager: staffId, projectManagerName: nickName };
			}
		}
		if (defaultData.type === 'edit') {
			this.title = '编辑评估计划';
			this.isEdit = true;
			this.getDetails(defaultData.id);
		}
		if (defaultData.type === 'view') {
			this.title = '计划详情';
			this.isView = true;
			this.getDetails(defaultData.id);
		}
	},
	methods: {
		// 获取详情
		getDetails(planId) {
			const loading = Loading.service({ target: '.formBox' });

			this.$axios
				.post('/v1/internal/talent/getPlanDetail', { request: { planId } })
				.then((res) => {
					loading.close();
					if (res?.code) {
						return;
					}
					const { startTime, endTime, provinceCode, cityCode, userList } = res;
					const data = { ...res };

					if (startTime && endTime) {
						data.date = [moment(startTime).format('YYYY-MM-DD'), moment(endTime).format('YYYY-MM-DD')];
						this.$delete(data, 'startTime');
						this.$delete(data, 'endTime');
					}
					if (provinceCode && cityCode) {
						data.city = [provinceCode, cityCode];
						this.$delete(data, 'provinceCode');
						this.$delete(data, 'cityCode');
					}
					this.formModel = data;
					if (userList?.length) {
						this.userList = [...userList.map((val, index) => ({ ...val, index }))];
					}
				})
				.catch(() => loading.close());
		},
		// 创建评估计划
		submit(form) {
			const params = {
				...this.formModel,
				userList: this.userList,
				filePath: this.uploadData.key,
				deleteUserIds: this.deleteUserIds,
				updateUserIds: this.updateUserIds
			};

			if (this.formModel.city?.length) {
				params.provinceCode = this.formModel.city[0];
				params.cityCode = this.formModel.city[1];
				this.$delete(params, 'city');
			}
			if (this.formModel.date?.length) {
				params.startTime = this.formModel.date[0];
				params.endTime = this.formModel.date[1];
				this.$delete(params, 'date');
			}
			form.validate((valid) => {
				if (valid) {
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/internal/talent/savePlan', { request: params })
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.$message.success('保存成功');
							// this.$router.go(-1);
							this.$emit('cancel');
						})
						.catch(() => {
							loading.close();
						});
				}
			});
		},
		// 添加评估对象
		submitForm() {
			checkFormList(this.$refs.formItemList)
				.then(() => {
					this.userList = [...this.userList, ...this.dialogList].map((val, index) => ({ ...val, index }));
					this.addVisible = false;
				})
				.catch(() => {});
		},
		handleEdit(row) {
			this.rtitle = '编辑';
			this.editData = { ...row };
			this.editVisible = true;
		},
		handleDel(row) {
			// this.$delete(this.userList, row.index);
			this.userList = this.userList.filter(({ index }) => index !== row.index).map((val, index) => ({ ...val, index }));
			if (row.id && !this.deleteUserIds.includes(row.id)) {
				this.deleteUserIds.push(row.id);
			}
		},
		handleEditSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					if (this.rtitle === '新增') {
						this.userList = this.userList.concat(this.editData).map((val, index) => ({ ...val, index }));
					} else {
						this.$set(this.userList, this.editData.index, this.editData);
					}
					if (!this.updateUserIds.includes(this.editData.id) && this.editData.id) {
						this.updateUserIds.push(this.editData.id);
					}
					this.editVisible = false;
					this.editData = {};
				}
			});
		}
		// handleFileChange(file, fileList) {
		// 	if (file.name) {
		// 		const inx = file.name.lastIndexOf('.');
		// 		const format = file.name.substr(inx + 1);

		// 		if (!['xlsx', 'XLSX'].includes(format)) {
		// 			this.fileList = [];
		// 			this.$message.warning('请上传xlsx格式文件');
		// 			return;
		// 		}
		// 	}

		// 	this.fileList = fileList;
		// },
		// handleRemove(file, fileList) {
		// 	this.fileList = fileList;
		// },
		// handleUpload() {
		// 	if (!this.fileList?.length) {
		// 		return;
		// 	}

		// 	const reader = new FileReader();

		// 	let wb = {};

		// 	reader.onload = (e) => {
		// 		const data = e.target.result;
		// 		const XLSX = require('xlsx');

		// 		wb = XLSX.read(data, { type: 'binary' });

		// 		const arr = XLSX.utils.sheet_to_json(wb.Sheets.Sheet);

		// 		if (arr?.length > 2) {
		// 			this.userList = arr.slice(2);
		// 			this.uploadVisible = false;
		// 			this.fileList = [];
		// 		}
		// 	};
		// 	reader.readAsBinaryString(this.fileList[0].raw);
		// },
		// downloadExcel() {
		// 	const headerCols = [
		// 		{
		// 			label: ' ',
		// 			key: 's',
		// 			children: [{ label: '*序号', key: 'sort' }]
		// 		},
		// 		{
		// 			label: '被评估人信息',
		// 			key: 'bpgr',
		// 			children: [
		// 				{ label: '*姓名', key: 'userName' },
		// 				{ label: '*一级组织', key: 'firstOrg' },
		// 				{ label: '二级组织', key: 'secondOrg' },
		// 				{ label: '三级组织', key: 'thirdOrg' },
		// 				{ label: '四级组织', key: 'fourthOrg' },
		// 				{ label: '*职位', key: 'officeName' },
		// 				{ label: '下属人数', key: 'subordinateNum' },
		// 				{ label: '直接上级', key: 'directSupervisor' }
		// 			]
		// 		},
		// 		{
		// 			label: '评估师',
		// 			key: 'pgs',
		// 			children: [
		// 				{ label: '*评估人', key: 'evaluateUserName' },
		// 				{ label: '*复核人', key: 'reviewUserName' }
		// 			]
		// 		}
		// 	];

		// 	json2excel(
		// 		[
		// 			headerCols,
		// 			...[] // 数据
		// 		],
		// 		'人才评估模板',
		// 		null, // callback
		// 		{
		// 			header: [
		// 				{
		// 					target: ['userName', 'firstOrg', 'officeName', 'evaluateUserName', 'reviewUserName'],
		// 					style: {
		// 						s: {
		// 							font: {
		// 								color: { rgb: 'FF0000' }, // 字体红色
		// 								sz: 10
		// 							}
		// 						},
		// 						t: 's'
		// 					}
		// 				}
		// 			],
		// 			col: [],
		// 			row: [
		// 				{
		// 					target: [0, 1],
		// 					style: {
		// 						s: {
		// 							font: {
		// 								sz: 10
		// 							},
		// 							fill: { fgColor: { rgb: 'C3EDEA' } } // fgColor
		// 						},
		// 						t: 's'
		// 					}
		// 				}
		// 			]
		// 		}
		// 	);
		// }
	}
};
</script>
<style lang="scss">
.assessBoxCreate {
	.app-container {
		color: #484848;
		padding: 0;
		// background-color: #f6f7fb;

		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			// margin: 20px 0;

			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
		}
	}

	.import_dialog_desc {
		text-align: center;
		margin-top: 15px;

		.import_dialog_desc_download_text {
			color: rgb(64, 158, 255);
			cursor: pointer;
		}
	}
}
.addFunctionsModal {
	display: inline-flex;
	align-items: center;
	width: 100%;
	color: #409eff;
	cursor: pointer;
	margin: 20px 0 30px 0;
	justify-content: center;
}
.formBox {
	padding: 20px;
	background: #fff;

	// margin: 20px;
	.el-form-item__content {
		.el-tag {
			margin: 0 10px 10px 0;
		}

		.el-range-separator {
			width: 20px;
		}
	}
}
</style>

<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			@rowClick="rowClick"
			showOrder
			:activeTab="activeTab"
			custom
		/>
	</div>
</template>

<script>
/* 我测评的 */
import baseTable from '@/views/components/components-base-table';
import { setUrlParams } from '@/utils/util';
import moment from 'moment';
// import { queryItem } from './pageConfig.js';
// import moment from 'moment';
export default {
	name: 'pendingAssessment',
	components: {
		baseTable
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		applyTypeList() {
			return this.$store.state.approval.applyTypeList;
		},
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		statusOpts() {
			const statusOpts = this.getOptsById('innerTalentStatus');

			let opts = [];

			switch (this.pageName) {
				case 'PendingAssessment': // 我测评的 - 待处理
					opts = statusOpts.filter(({ dictId }) => ['0', '1', '4'].includes(dictId));
					break;
				case 'ProcessedAssessments': // 我测评的 - 已处理
					opts = statusOpts.filter(({ dictId }) => ['2', '3', '5', '6', '7'].includes(dictId));
					break;
				case 'PendingReviewed': // 我复核的 - 待处理
					opts = statusOpts.filter(({ dictId }) => ['0', '1', '2', '4', '5'].includes(dictId));
					break;

				default:
					break;
			}
			return opts;
		},
		// 我复核
		isReviewed() {
			return ['PendingReviewed', 'ProcessedReviewed'].includes(this.pageName);
		},
		tableCols() {
			return [
				{
					label: '计划名称',
					key: 'planName'
				},
				{
					label: '项目经理',
					key: 'projectManagerName'
				},
				{
					label: '公司名称',
					key: 'company'
				},
				{
					label: '被评估人',
					key: 'userName'
				},
				{
					label: '组织',
					key: 'orgName'
				},
				{
					label: '职位',
					key: 'officeName'
				},
				{
					label: '评估类型',
					key: 'evaluationType',
					formatter: (v, r, fn) => {
						const opts = fn('evaluationType') || [];

						return opts.find(({ dictId }) => dictId === v)?.dictName;
					}
				},
				{
					label: this.isReviewed ? '评估人' : '复核人',
					key: this.isReviewed ? 'evaluateUserName' : 'reviewUserName'
				},
				{
					label: '截止完成时间',
					key: 'endTime',
					formatter: (val) => moment(val).format('YYYY-MM-DD')
				},
				{
					label: '状态',
					key: 'statusName',
					width: 120
					// formatter: (v) => this.statusOpts.find((it) => it.value === v)?.label
				},
				['PendingAssessment', 'PendingReviewed'].includes(this.pageName) && {
					label: '操作',
					key: 'cz',
					width: '120px',
					fixed: 'right',
					render: (row) => (
						<div style="display:inline-flex;align-items: center;height: 22px">
							{this.permissionControlBtns(this.pageName, 'Edit') &&
								!(this.pageName === 'PendingReviewed' && !['2', '5'].includes(row.status)) && (
									<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
										{this.pageName === 'PendingReviewed' ? '复核' : '评估'}
									</el-button>
								)}
						</div>
					)
				}
			].filter((v) => v);
		},
		queryItem() {
			return [
				{
					label: '计划名称',
					key: 'planName',
					type: 'input',
					// async: (query) =>
					// 	this.$axios
					// 		.post('/v1/internal/talent/getPlanList', { request: { pageNo: 1, pageSize: 40, planName: query } })
					// 		.then((res) => res.list?.map(({ planName, id }) => ({ dictId: id, dictName: planName }))),
					span: 4
				},
				{ label: '评估类型', key: 'evaluationType', type: 'select', groupId: 'evaluationType', span: 4 },
				{
					label: '状态',
					key: 'status',
					type: 'select',
					span: 4,
					options: () => this.statusOpts
				},
				// { label: '姓名', key: 'personalName', type: 'input', span: 6 },
				{ label: '被评估人', key: 'userName', type: 'input', span: 4 },
				{ label: this.isReviewed ? '评估人' : '复核人', key: this.isReviewed ? 'evaluateUserName' : 'reviewUserName', type: 'input', span: 4 }
			];
		}
	},
	data() {
		return {
			formModel: {}
		};
	},
	created() {},
	mounted() {
		console.log(this.pageName, 'activeTab');
	},
	methods: {
		getTableData(params, callback) {
			if (params.request) {
				params.request.queryType = this.getQueryTypeByPageName();
			}
			callback(this.$axios.post('/v1/internal/talent/getReportListByType', params));
		},
		getQueryTypeByPageName() {
			let queryType = '';

			switch (this.pageName) {
				case 'PendingAssessment':
					queryType = '1';
					break;
				case 'ProcessedAssessments':
					queryType = '2';
					break;
				case 'PendingReviewed':
					queryType = '3';
					break;
				case 'ProcessedReviewed':
					queryType = '4';
					break;

				default:
					break;
			}
			return queryType;
		},
		// 点击table行 跳转到评估详情
		rowClick({ planId, userId, evaluationType }) {
			this.$router.push({
				name: 'EvaluationDetail',
				query: { params: setUrlParams({ userId, planId, evaluationType, type: 'view', pageName: this.pageName }) }
			});
		},
		// 进入评估 复核
		handleEdit({ planId, userId, evaluationType }, e) {
			e.stopPropagation();
			// if (['PendingAssessment'].includes(this.pageName)) {
			// 	// 获取音频权限
			// 	navigator.mediaDevices
			// 		?.getUserMedia({ audio: true })
			// 		.then(() => {})
			// 		.catch(() => {
			// 			this.$message.info('请允许使用麦克风');
			// 		});
			// }

			this.$router.push({
				name: 'EvaluationDetail',
				query: { params: setUrlParams({ userId, planId, evaluationType, type: 'edit', pageName: this.pageName }) }
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>

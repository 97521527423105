<template>
	<div class="staffWrapBox">
		<el-tabs v-model="activeName">
			<el-tab-pane :lazy="true" label="我管理的" name="0" v-if="permissionControl('IManage') && isLevelOne">
				<IManageTabs ref="pane0" pageName="IManage"></IManageTabs>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="我测评的" name="1" v-if="permissionControl('IEvaluated')">
				<IEvaluated ref="pane1" pageName="IEvaluated"></IEvaluated>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="我复核的" name="2" v-if="permissionControl('IReviewed')">
				<IEvaluated ref="pane2" pageName="IReviewed"></IEvaluated>
			</el-tab-pane>
			<!-- <el-tab-pane :lazy="true" label="我的评估数据" name="3" v-if="permissionControl('EvaluationDataStatistics')">
				<IEvaluated ref="pane3" pageName="EvaluationDataStatistics"></IEvaluated>
			</el-tab-pane> -->
		</el-tabs>
	</div>
</template>

<script>
/* 人才评估 员工应用*/
import IEvaluated from './iEvaluated/index.vue'; // 我测评的 复核
import IManageTabs from './IManageTabs.vue'; // 我测评的 复核

export default {
	name: 'evaluateTalents',
	components: {
		IEvaluated,
		IManageTabs
	},
	data() {
		return {
			activeName: '',
			listQuery: {},
			isLevelOne: false // 是否一级评估
		};
	},

	computed: {
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		}
	},
	created() {},
	methods: {
		getAppraiserList() {
			return this.$axios.post('/v1/internal/talent/getAppraiserList', { request: { pageNo: 1, pageSize: 999 } }).then((res) => {
				if (res.code) {
					return;
				}
				if (this.userInfo.staffId) {
					const arr = res.list;

					const pgData = arr.find(({ staffId }) => staffId.toString() === this.userInfo.staffId);

					this.isLevelOne = pgData.qualificationLevel === 1;
				}
				return res;
			});
		}
	},
	async mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		const { activeName } = data || {};

		this.activeName = activeName || '';

		await this.getAppraiserList();
		const list = [
			this.isLevelOne && { name: '0', key: 'IManage' },
			{ name: '1', key: 'IEvaluated' },
			{ name: '2', key: 'IReviewed' }
			// { name: '3', key: 'EvaluationDataStatistics' }
		].filter((v) => v);

		// 设置tab默认
		this.setActiveTab(this.$route.name, list);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	}
};
</script>
<style scoped>
.staffWrapBox {
	background: #fff;
	/* margin: 0 20px; */
	padding: 10px 30px;
	min-height: calc(100vh - 90px);
}
</style>

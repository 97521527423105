<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleTabs">
			<el-tab-pane :lazy="true" label="评估计划" name="1" v-if="permissionControl('EmployeeEvaluationPlan')">
				<EvaluationPlan ref="pane0" pageName="EmployeeEvaluationPlan" v-if="activeName === '1'"></EvaluationPlan>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="报告进度" name="2" v-if="permissionControl('ReportByA')">
				<AssessmentReport ref="pane1" pageName="ReportByA" v-if="activeName === '2'"></AssessmentReport>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="评估数据" name="3" v-if="permissionControl('DataDetails_personal')">
				<DataDetails ref="pane2" pageName="DataDetails_personal" v-if="activeName === '3'"></DataDetails>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="人才地图" name="4" v-if="permissionControl('TalentMap_personal')">
				<Map ref="pane3" pageName="TalentMap_personal" v-if="activeName === '4'"></Map>
			</el-tab-pane>
			<el-tab-pane :lazy="true" label="评估报告" name="5" v-if="permissionControl('EvaluationReport')">
				<ProjectReport ref="pane4" pageName="EvaluationReport" v-if="activeName === '5'"></ProjectReport>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
/* 我管理的 员工应用*/
import EvaluationPlan from '../talent-assessment/evaluationPlan/index.vue'; // 评估计划
import AssessmentReport from './assessmentReport.vue'; // 报告进度
import DataDetails from '../talent-assessment/evaluationResult/dataDetails.vue'; // 评估数据
import Map from '@/views/talent-assessment/list/map';
import ProjectReport from '@/views/talent-assessment/evaluationResult/projectReport';

export default {
	name: 'evaluateTalents',
	props: { pageName: {} },
	components: {
		EvaluationPlan,
		AssessmentReport,
		DataDetails,
		Map,
		ProjectReport
	},
	data() {
		return {
			activeName: '',
			listQuery: {}
		};
	},

	computed: {},
	created() {},
	methods: {
		handleTabs() {
			sessionStorage.setItem(
				`${this.pageName}Data`,
				JSON.stringify({
					activeName: this.activeName
				})
			);
		}
	},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.pageName}Data`));

		const { activeName } = data || {};

		this.activeName = activeName || '1';

		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: 'EmployeeEvaluationPlan' },
			{ name: '2', key: 'ReportByA' },
			{ name: '3', key: 'DataDetails_personal' },
			{ name: '4', key: 'TalentMap_personal' },
			{ name: '5', key: 'EvaluationReport' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.pageName}Data`,
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	}
};
</script>
<style scoped>
.staffWrapBox {
	background: #fff;
	/* margin: 0 20px; */
	padding: 10px 30px;
}
</style>
